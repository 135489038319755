import React from 'react'
import {Box,Text,Heading,Flex} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import {StaticImage} from 'gatsby-plugin-image'


const Judenbuche = () => {
  return(
    <>
    <Heading pt="3" px="3" ml="1" mt="10" variant="titel">Annette von Droste-Hülshoff: <br/>Die Judenbuche</Heading>
    <Flex  mx="2"  direction={["column","column","row"]}  maxWidth="1100">
  <MeinSEO title="Die Judenbuche. Ein Kriminalfall aus romantischer Zeit" description="Lesung mit Michael Quast"/>
 <Box flex="1" pr={["0","0","3"]} width={["100%","100%","50%"]}>

 
<Box><Text pl="3" fontWeight="400">Ein Kriminalfall aus romantischer Zeit<br/>
Lesung &amp; Musik
</Text>
</Box>

 <StaticImage src="../images/web/buche.jpg" 
     alt="Teaser zur Judenbuche"
    />
  
  <Text variant="zitat" >
    „Denn wer nach seiner Überzeugung handelt, und sei sie noch so mangelhaft, kann nie ganz zugrunde gehen, wogegen nichts seelentötender wirkt, als gegen das innere Rechtsgefühl das äußere Recht in Anspruch zu nehmen.“
    </Text>
</Box>
<Box shadow="md" bg="gray.200" px="3" mt="94px" flex="1 1"  >
 
 
     <Heading variant="title"> Lesung</Heading>
     <Text m="0" p="0">Michael Quast</Text>
     <Heading p="0" variant="title" >Gitarre</Heading>
     <Text m="0">Vanessa Heinisch</Text> 
  

 

   

     <Heading variant="title">Lorch</Heading>
      <Text> 
         Donnerstag, 7. Juli um 19:30 Uhr <br/>Hilchenhaus 
      </Text>
      <Heading variant="title">Oberwesel</Heading>
     <Text>Freitag, 8. Juli um 19:30 Uhr <br/>Klostergarten </Text>
      




  </Box>
</Flex>
</>
  )
}
 export default Judenbuche


  